import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

import { NAVIGATION_ITEMS } from './navigation-cache/navigation-cache.model';

export interface RouteIndex {
  index: number;
  subIndex: number;
}

export interface Route {
  index: string;
  subIndex?: string[];
}

@Injectable({
  providedIn: 'root',
})
export class ActiveRouteService {
  static readonly clearIndex = -1;

  static readonly tableRoutes: Route[] = [
    {
      index: 'biblia',
      subIndex: ['przeklady', 'wyszukaj', 'opisy']
    },
    {
      index: 'strong',
      subIndex: ['ubg', 'konkordancja']
    },
    {
      index: NAVIGATION_ITEMS.KOMENTARZE,
      subIndex: ['ksiega=pierwszy-list-do-koryntian']
    },
    { index: NAVIGATION_ITEMS.OPRACOWANIA },
    { index: NAVIGATION_ITEMS.APOLOGETYKA },
    {
      index: NAVIGATION_ITEMS.KSIAZKI,
      subIndex: [
        null,
        'kosciol-pielgrzym',
        'psychologia-i-kosciol',
        'inwazja-okultyzmu',
        'zwiedzione-chrzescijanstwo'
      ]
    },
  ];

  static readonly aboutIndexes: string[] = ['o-autorze', 'o-platformie', 'kontakt'];

  private readonly _routeChange = new Subject<RouteIndex>();

  get routeChange$(): Observable<RouteIndex> {
    return this._routeChange.asObservable();
  }

  changeIndexes(index: number, subIndex: number): void {
    this._routeChange.next({ index, subIndex });
  }
}
